import { checkHashFormat, getTrimHash, getFullDate } from "../utilities";

function BBHash(props: any) {
  let { data, docName, onSetVersion } = props;

  const handleMultiple = () => {
    if (!docName) return;
    onSetVersion(data.document.version);
  };

  return (
    <div className="DetailsWrapper" onClick={() => handleMultiple()}>
      <div className="Additionals Col">
        <span className="Title">Hash information</span>

        <div className="Field">
          <span>Hash:</span>
          <span className="info hash" title={data?.hash}>
            {checkHashFormat(data?.hash)
              ? getTrimHash(data?.hash, 15)
              : data?.hash}
          </span>
        </div>

        <div className="Field">
          <span>Algorithm:</span>
          <span className="info">{data?.algorithm}</span>
        </div>

        <div className="Field">
          <span>Date:</span>
          <span>{getFullDate(new Date(data?.datetime))}</span>
        </div>
      </div>
    </div>
  );
}

export default BBHash;
