import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
function BlockList(props: any) {
  const { documents, currDocument, signatures } = props.data;
  const biometricSignatures = signatures.filter(
    (signature: any) => signature.hash
  );

  const activityHashes = documents.reduce((activityHashes: any[], doc: any) => {
    if (doc.activityHash) {
      activityHashes.push({ hash: doc.activityHash });
    }
    return activityHashes;
  }, []);

  const blockHashes = documents
    .slice(0, 10)
    .concat(biometricSignatures.slice(0, 10))
    .concat(activityHashes.slice(0, 10));

  const [expanded, setExpanded] = useState(false);
  const [crop, setCrop] = useState(10);
  const [scrollEnd, setScrollEnd] = useState(false);
  const [list, setList] = useState(blockHashes);
  const [scrollLevel, setScrollLevel] = useState(0);

  const handleExpand = () => {
    setExpanded(!expanded);
    setCrop(0);
    handleLoadMore();
  };

  const handleScroll = (e: any) => {
    setScrollLevel(e.target.offsetHeight + e.target.scrollTop);
    if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };

  const handleLoadMore = () => {
    setList(blockHashes.slice(0, crop + 20));
    setCrop(crop + 20);
  };

  // Remove duplicates if there is any
  const uniqueList: any = [];
  list.forEach((entry: any) => {
    const isDuplicate = uniqueList.some(
      (uniqueEntry: any) => uniqueEntry.hash === entry.hash
    );
    if (!isDuplicate) {
      uniqueList.push(entry);
    }
  });

  return (
    <div className="CollapserWrapper">
      <div className="CollapserHeader" onClick={handleExpand}>
        <FontAwesomeIcon
          className={!expanded ? "icon" : "icon reverse"}
          icon={faAngleDown}
        />
        Hashes in this Combined Hash:
      </div>
      <div
        className="CollapserContent"
        onScroll={handleScroll}
        style={!expanded ? { display: "none" } : { display: "flex" }}
      >
        {uniqueList.map((e: any, i: number) => {
          const hasOwnerShip = currDocument
            ? e.hash === currDocument.notarizedHash
            : false;
          return (
            <div
              className="Line"
              // href={getdocumentHashUrl(e?.hash)}
              key={`${e?.hash}-${i}`}
            >
              <span className={hasOwnerShip ? "owner" : ""}>{e?.hash}</span>
            </div>
          );
        })}
        {uniqueList.length > 4 && (
          <span
            className="Dots"
            style={
              scrollEnd ? { visibility: "hidden" } : { visibility: "visible" }
            }
          >
            ...
          </span>
        )}
        {crop < documents.length ? (
          <span className="LoadMore" onClick={handleLoadMore}>
            Load more
          </span>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default BlockList;
