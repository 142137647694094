export enum $DataType {
  "blockHash" = "blockHash",
  "documentHash" = "documentHash",
  "signaturehash" = "signatureHash",
  "txId" = "txId",
  "BBHash" = "BBHash",
  "blockBBHash" = "blockBBHash"
}

export enum $BlockchainsEnum {
  "Ethereum" = "Ethereum",
  "Bitcoin" = "Bitcoin",
  "EOS" = "EOS",
  "Ton" = "Ton",
  "Solana" = "Solana",
}
