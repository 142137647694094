import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { checkHashFormat, getTrimHash, getFullDate } from "../utilities";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

function DocumentNameDetails(props: any) {
  let { data, docName, onSetVersion } = props;

  const handleMultiple = () => {
    if (!docName) return;
    onSetVersion(data.document.version);
  };

  return (
    <div className="DetailsWrapper" onClick={() => handleMultiple()}>
      <div className="Additionals Col">
        <span className="Title">File information</span>
        {docName && (
          <div className="Field">
            <span>Version:</span>
            <span className="info" title={data?.document?.hash}>
              {data?.document?.version}
            </span>
          </div>
        )}

        <div className="Field">
          <span>Hash:</span>
          <span className="info hash" title={data?.document?.hash}>
            {checkHashFormat(data?.document?.hash)
              ? getTrimHash(data?.document?.hash, 15)
              : data?.document?.hash}
            <FontAwesomeIcon
              icon={faCopy}
              onClick={() =>
                navigator.clipboard.writeText(data?.document?.hash)
              }
            />
          </span>
        </div>

        <div className="Field">
          <span>Size / Type:</span>
          <span className="info">
            {data?.document?.size}KB / {data?.document?.type}
          </span>
        </div>

        <div className="Field">
          <span>Date:</span>
          <span>{getFullDate(new Date(data?.document?.datetime))}</span>
        </div>

        {!docName && (
          <div className="Field">
            <span>Version:</span>
            <span className="info" title={data?.document?.hash}>
              {data?.document?.version}
            </span>
          </div>
        )}
      </div>
      <div className="Summary Col">
        <span className="Title">Activity</span>
        <div className="Field">
          <span>Blockchain validations:</span>
          <span>{data?.document?.validations?.length}</span>
        </div>

        <div className="Field">
          <span>Document signatures:</span>
          <span>{data?.document?.signatures?.length}</span>
        </div>

        <div className="Field">
          <span>Document views:</span>
          <span>{data?.document?.views}</span>
        </div>

        <div className="Field">
          <span>Document downloads:</span>
          <span>{data?.document?.downloads}</span>
        </div>
      </div>
    </div>
  );
}

export default DocumentNameDetails;
